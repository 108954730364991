import useDeletedFinishPoint from 'hooks/mission/useChangeFinishPoint'
import useChangeStartPoint from 'hooks/mission/useChangeStartPoint'
import useZoomToPoint from 'hooks/useZoomToPoint'
import { Point } from 'ol/geom'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import useMissionStore from 'store/mission'
import { TRANSFER_TYPE } from 'utils/constants'
import { IPoint } from 'utils/interfaces'
import { useShallow } from 'zustand/react/shallow'

import CommonRouteItem from './CommonRouteItem/CommonRouteItem'

const StartFinishPointItem = ({
  type,
  missionPoint,
  hideActions,
}: {
  type: TRANSFER_TYPE
  missionPoint: IPoint
  hideActions?: boolean
}) => {
  const {
    missionPoints,
    setHighlightedFeature,
    deleteStartPoint,
    deleteFinishPoint,
    setEditingPoint,
    cancelEditingPoint,
    editPoint,
    missionInfo,
  } = useMissionStore(
    useShallow(state => ({
      missionPoints: state.missionPoints,
      setHighlightedFeature: state.setHighlightedFeature,
      deleteStartPoint: state.deleteStartPoint,
      deleteFinishPoint: state.deleteFinishPoint,
      setEditingPoint: state.setEditingPoint,
      cancelEditingPoint: state.cancelEditingPoint,
      editPoint: state.editPoint,
      missionInfo: state.missionInfo,
    }))
  )
  const { mutateAsync: setChangeStartPoint } = useChangeStartPoint()
  const { mutateAsync: setChangeFinishPoint } = useDeletedFinishPoint()
  const { t } = useTranslation()
  const { zoomToPoint } = useZoomToPoint()

  const onPointClicked = () => {
    setHighlightedFeature(missionPoint)
    zoomToPoint(new Point(missionPoint.geometry.coordinates).transform('EPSG:4326', 'EPSG:3857'))
  }

  const onEditPointClicked = () => {
    setEditingPoint(missionPoint)
  }

  const onRemovePointClicked = () => {
    const missionId = missionInfo?.id || 0
    if (type === TRANSFER_TYPE.START) {
      setChangeStartPoint({ id: missionId, startingPoint: null }).then(() => {
        deleteStartPoint()
      })
    } else {
      setChangeFinishPoint({ id: missionId, finishingPoint: null }).then(() => {
        deleteFinishPoint()
      })
    }
  }

  const onCancelEditingClicked = (e: any) => {
    e.stopPropagation()
    cancelEditingPoint()
  }

  const onSaveClicked = async (e: any) => {
    e.stopPropagation()
    const missionId = missionInfo?.id || 0
    if (type === TRANSFER_TYPE.START) {
      const startPoint = await setChangeStartPoint({
        id: missionId,
        startingPoint: missionPoints?.tempPoint?.geometry,
      })
      editPoint(type, missionPoints.tempPoint, startPoint)
    } else {
      const finishPoint = await setChangeFinishPoint({
        id: missionId,
        finishingPoint: missionPoints?.tempPoint?.geometry,
      })
      editPoint(type, missionPoints.tempPoint, undefined, finishPoint)
    }
  }

  const text = useMemo(() => {
    switch (type) {
      case TRANSFER_TYPE.START:
        return t('view_zones.start_point')
      case TRANSFER_TYPE.FINISH:
        return t('view_zones.finish_point')
    }
  }, [t, type])

  const editing = useMemo(() => {
    return missionPoints.editingPoint === missionPoint
  }, [missionPoints.editingPoint, missionPoint])

  return missionPoint?.id ? (
    <CommonRouteItem
      id={missionPoint.id}
      text={text}
      itemType={type}
      editing={editing}
      onClick={onPointClicked}
      onEdit={onEditPointClicked}
      onCancel={onCancelEditingClicked}
      onRemove={onRemovePointClicked}
      onSave={onSaveClicked}
      hideActions={hideActions}
    />
  ) : null
}

export default StartFinishPointItem
