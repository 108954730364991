import { Feature } from 'ol'
import { Extent } from 'ol/extent'
import { LineString, Point, Polygon } from 'ol/geom'
import { useState } from 'react'
import useMapStore from 'store/map'
import { IMission } from 'utils/interfaces'

interface Props {
  robotOnMap?: Feature<Point>
  missionInfoData?: IMission
  applyMissionLogic?: boolean
}

const useRobotZoom = ({ robotOnMap, missionInfoData, applyMissionLogic = false }: Props) => {
  const [zoomedIn, setZoomedIn] = useState(false)
  const map = useMapStore(s => s.map)

  const zoomToRobot = () => {
    if (!robotOnMap) return

    if (applyMissionLogic || !zoomedIn) {
      map?.getView().setCenter(robotOnMap.getGeometry()?.getCoordinates())
      map?.getView().setZoom(18)
      setZoomedIn(!applyMissionLogic) // Инвертируем значение zoomedIn, если applyMissionLogic === false
    } else {
      const geometry = missionInfoData?.cleaningZones[0]?.geometry || missionInfoData?.pointRoutes?.[0]?.geometry
      if (!geometry) return

      const featureGeom =
        geometry.type === 'LineString'
          ? new LineString(geometry.coordinates).transform('EPSG:4326', 'EPSG:3857')
          : // @ts-ignore
            new Polygon(geometry.coordinates).transform('EPSG:4326', 'EPSG:3857')
      const feature = new Feature({
        geometry: featureGeom,
      })
      map?.getView().fit(feature?.getGeometry()?.getExtent() as Extent, { padding: [50, 50, 50, 50] })
      setZoomedIn(false)
    }
  }

  return zoomToRobot
}

export default useRobotZoom
