import { useMutation } from '@tanstack/react-query'
import { MainApi } from 'api/MainApi'
import { IZone, ZoneRouteUpdateData } from 'utils/interfaces'

interface ReceiveData {
  zone: IZone
}

const useEditCleaningZoneRoute = () =>
  useMutation<ReceiveData, Error, ZoneRouteUpdateData>({
    mutationFn: MainApi.patchCleaningZoneRoute,
  })

export default useEditCleaningZoneRoute
