import EventEmitter from 'events'
import { useEffect, useRef, useState } from 'react'
import { TELEOP_MESSAGE_TYPES } from 'utils/constants'
import { MessageTeleop, MessageTeleopResponse } from 'utils/interfaces'

const WEBSOCKET_PATH = '/api/websocketto/'

function useManualMode(robotId: number | undefined) {
  const socket = useRef<WebSocket | null>(null)
  const eventEmitter = useRef(new EventEmitter())

  const [engine, setEngine] = useState<number | null>(null)
  const [rudder, setRudder] = useState<number | null>(null)
  const [heartbit, setHeartbit] = useState<number | null>(null)
  const [webrtcUrl, setWebrtcUrl] = useState<string | null>(null)

  const messageToString = (json: MessageTeleop) => JSON.stringify(json)

  const initSocket = () => {
    const scheme = 'ws' + (window.location.protocol === 'https:' ? 's' : '')
    const url = `${scheme}://${window.location.host}${WEBSOCKET_PATH}`

    socket.current = new WebSocket(url)
  }

  const _teleopInit = () => {
    if (!robotId) return

    const data = {
      type: TELEOP_MESSAGE_TYPES.INIT,
      robot_id: robotId,
      previous_mcs_mode: 0,
    }

    const str = messageToString(data)
    socket.current?.send(str)
  }

  const teleopInit = () => {
    if (!socket.current) return
    socket.current.onopen = _teleopInit
  }

  const teleopReload = () => {
    _teleopInit()
  }

  const teleopExit = () => {
    if (!socket.current) return

    socket.current.send(
      messageToString({
        type: TELEOP_MESSAGE_TYPES.EXIT,
      })
    )
    socket.current.onopen = null
  }

  const teleopSoundOn = () => {
    if (!socket.current) return

    const data = {
      type: TELEOP_MESSAGE_TYPES.SOUND,
      enabled: true,
    }

    const str = messageToString(data)
    socket.current.send(str)
  }

  const teleopSoundOff = () => {
    if (!socket.current) return

    const data = {
      type: TELEOP_MESSAGE_TYPES.SOUND,
      enabled: false,
    }
    const str = messageToString(data)
    socket.current.send(str)
  }

  const teleopMovement = (type: 'engine' | 'rudder', value: number) => {
    if (!socket.current) return

    const data = {
      type: type === 'engine' ? TELEOP_MESSAGE_TYPES.ENGINE : TELEOP_MESSAGE_TYPES.RUDDER,
      value,
    }

    const str = messageToString(data)

    socket.current.send(str)
  }

  useEffect(() => {
    if (!socket.current) return

    const closeHandler = () => {
      // eslint-disable-next-line no-console
      console.log('Connection close ', WEBSOCKET_PATH)
    }

    const openHandler = () => {
      // eslint-disable-next-line no-console
      console.log('Connection open ', WEBSOCKET_PATH)
    }

    const errorHandler = (event: Event) => {
      // eslint-disable-next-line no-console
      console.log(event)
    }

    const messageHandler = (event: MessageEvent) => {
      try {
        if (!eventEmitter.current) return

        const data = JSON.parse(event.data)
        eventEmitter.current.emit(data.type, data)
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error)
      }
    }

    const changeEngine = (data: MessageTeleopResponse) => setEngine(Number(data.value) || null)
    const changeRudder = (data: MessageTeleopResponse) => setRudder(Number(data.value) || null)
    const changeHeartbit = (data: MessageTeleopResponse) => setHeartbit(Number(data.latency) || null)
    const setUrlWebrtc = (data: MessageTeleopResponse) => setWebrtcUrl(data.webrtc_url || null)

    socket.current.addEventListener('open', openHandler)
    socket.current.addEventListener('close', closeHandler)
    socket.current.addEventListener('error', errorHandler)
    socket.current.addEventListener('message', messageHandler)

    eventEmitter.current.addListener(TELEOP_MESSAGE_TYPES.ENGINE_FEEDBACK, changeEngine)
    eventEmitter.current.addListener(TELEOP_MESSAGE_TYPES.RUDDER_FEEDBACK, changeRudder)
    eventEmitter.current.addListener(TELEOP_MESSAGE_TYPES.HEARTBIT_FEEDBACK, changeHeartbit)
    eventEmitter.current.addListener(TELEOP_MESSAGE_TYPES.TELEOP_INITIALIZED, setUrlWebrtc)

    return () => {
      if (!socket.current) return
      socket.current.close()

      socket.current.removeEventListener('open', openHandler)
      socket.current.removeEventListener('close', closeHandler)
      socket.current.removeEventListener('error', errorHandler)
      socket.current.removeEventListener('message', messageHandler)

      eventEmitter.current.removeAllListeners()
    }
  }, [socket.current, robotId])

  return {
    engine,
    rudder,
    heartbit,
    webrtcUrl,

    initSocket,
    teleopInit,
    teleopReload,
    teleopExit,
    teleopSoundOn,
    teleopSoundOff,
    teleopMovement,
  }
}

export default useManualMode
