import { Button, CircularProgress } from '@mui/material'
import { ApiError } from 'api/schema'
import { ReactComponent as AddIcon } from 'images/icons/add.svg'
import { useRef, useState } from 'react'
import useMapStore from 'store/map'

interface Props {
  missionId: number
  onError: (error: string) => void
  onUpload: () => void
}

const GeoTiffUpload = ({ missionId, onError, onUpload }: Props) => {
  const uploadGeotiff = useMapStore(s => s.uploadGeotiff)
  const checkGeotiffStatus = useMapStore(s => s.checkGeotiffStatus)
  const [loading, setLoading] = useState(false)
  const inputRef = useRef<HTMLInputElement | null>(null)

  const handleClick = () => {
    inputRef.current?.click()
  }

  const handleFileSelect = async (e: React.ChangeEvent<HTMLInputElement>) => {
    e.stopPropagation()
    e.preventDefault()

    const files = e.target.files
    /* eslint-disable-next-line no-console */
    console.log('uploading files - ', files)
    if (!files) return
    onError('')
    setLoading(true)
    const result = await uploadGeotiff(files[0], missionId)
    if (result instanceof ApiError) {
      setLoading(false)
      const message = result.body.details?.file?.[0] || result.message
      return onError(message)
    }
    /* eslint-disable-next-line no-console */
    console.log('uploaded geotiff - ', result)
    const id = result.id

    const interval = setInterval(async () => {
      const statusResult = await checkGeotiffStatus(id)
      if (statusResult instanceof Error) {
        setLoading(false)
        clearInterval(interval)
        return onError(statusResult.message)
      }
      if (statusResult.status === 'ok') {
        setLoading(false)
        clearInterval(interval)
        onUpload()
      }
    }, 2000)
    // uploadGeotiff(files[0], missionId).then(result => {
    //   setLoading(false)
    //   if (result instanceof Error) {
    //     onError(result.message)
    //   }
    // })
  }

  // if (loading)
  // return (
  //   <ListItem>
  //     <CircularProgress />
  //   </ListItem>
  // )

  return (
    <>
      <Button
        onClick={handleClick}
        endIcon={loading ? <CircularProgress color="primary" size={18} /> : <AddIcon />}
        disabled={loading}
        variant="contained"
        style={{ minWidth: '150px' }}
      >
        New GeoTiff
      </Button>
      <input
        ref={inputRef}
        onChange={handleFileSelect}
        type="file"
        style={{ display: 'none' }}
        accept=" image/tif, .tif"
        multiple={false}
      />
    </>
  )
}

export default GeoTiffUpload
