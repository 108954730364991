import { useEffect, useState } from 'react'

export type MovementHanlder = (speed: number) => void

interface MovementHanlders {
  onEngine: MovementHanlder
  onRudder: MovementHanlder
}

interface Props {
  enabled: boolean
  movementHanlders: MovementHanlders
}

function useMoveKeyboardJoystick({ enabled = false, movementHanlders: { onEngine, onRudder } }: Props) {
  const stepSpeed = 0.25
  const maxSpeed = 1
  const minSpeed = 0
  const [posX, setPosX] = useState(0)
  const [posY, setPosY] = useState(0)

  const [speed, setSpeed] = useState(maxSpeed)

  useEffect(() => {
    if (!enabled) return

    const keyUpHandler = () => {
      setPosX(0)
      setPosY(0)
    }

    const keyDownHandler = (e: KeyboardEvent) => {
      e.preventDefault()

      if (e.code === 'ArrowUp') {
        onEngine(speed)
        setPosY(speed)
      }
      if (e.code === 'ArrowDown') {
        onEngine(-speed)
        setPosY(-speed)
      }
      if (e.code === 'ArrowRight') {
        onRudder(speed)
        setPosX(speed)
      }
      if (e.code === 'ArrowLeft') {
        onRudder(-speed)
        setPosX(-speed)
      }

      if (e.code === 'KeyW' && speed !== maxSpeed) {
        setSpeed(speed + stepSpeed)
      }
      if (e.code === 'KeyS' && speed !== minSpeed) {
        setSpeed(speed - stepSpeed)
      }
    }

    document.addEventListener('keydown', keyDownHandler)
    document.addEventListener('keyup', keyUpHandler)

    return () => {
      document.removeEventListener('keydown', keyDownHandler)
      document.removeEventListener('keyup', keyUpHandler)
    }
  }, [speed, enabled])

  return { x: posX, y: posY }
}

export default useMoveKeyboardJoystick
