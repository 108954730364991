import { Box, Button, Grid, IconButton, Modal, Stack, TextField, Tooltip, Typography } from '@mui/material'
import ManualModeMobile from 'components/ManualModeMobile/ManualModeMobile'
import RobotMissionMap from 'components/Robot/RobotMissionMap'
import RobotJoystick from 'components/RobotJoystick/RobotJoystick'
import useManualMode from 'hooks/useManualMode'
import useResize from 'hooks/useResize'
import useRobot from 'hooks/useRobot'
import useTimeBasedAction from 'hooks/useTimeBasedAction'
import { ReactComponent as DegreeSvg } from 'images/360Degree.svg'
import { ReactComponent as CameraSvg } from 'images/Camera.svg'
import { ReactComponent as IconCamSvg } from 'images/IconCam.svg'
// import ImageManualModeImage from 'images/ImageManualMode.png'
import { ReactComponent as QuestionMarkIcon } from 'images/icons/faq.svg'
import { useEffect, useMemo, useRef, useState } from 'react'
// import { BsCaretDownFill, BsCaretLeftFill, BsCaretRightFill, BsCaretUpFill, BsPauseFill } from 'react-icons/bs'
import { IoMdCloseCircleOutline } from 'react-icons/io'
import { useNavigate } from 'react-router-dom'
import { PATH } from 'utils/constants'

import ManualDataBlock from './ManualDataBlock'

import style from './ManualModePage.module.css'

const ManualModePage = () => {
  const navigate = useNavigate()
  const { data: robot } = useRobot()
  const { width } = useResize()
  // const [openModal, setOpenModal] = useState(false)

  const handleBackClicked = () => {
    robot?.id && navigate(`${PATH.ROBOT_LIST}/${robot.id}`)
  }

  const restartTimeout = null
  const video = useRef(null)
  const pc = useRef(null)
  const authHeader = useRef(null)
  const [showModal, setShowModal] = useState(true)
  const [nonAdvertisedCodecs, setNonAdvertisedCodecs] = useState([])
  const [sessionUrl, setSessionUrl] = useState(null)
  const [offerData, setOfferData] = useState(null)
  const [queuedCandidates, setQueuedCandidates] = useState([])

  const {
    engine,
    rudder,
    heartbit,
    webrtcUrl,
    initSocket,
    teleopInit,
    teleopReload,
    teleopExit,
    teleopSoundOn,
    teleopSoundOff,
    teleopMovement,
  } = useManualMode(42)

  const timeBasedAction = useMemo(
    () => ({
      time: 5000,
      onInactivity: () => {
        // eslint-disable-next-line no-console
        console.log('Робот бездействует')
        teleopExit()
      },
      onActivity: () => {
        // eslint-disable-next-line no-console
        console.log('Робот вновь запущен')

        teleopReload()
      },
    }),
    [robot?.id]
  )

  const {
    id: idAction,
    clearAction,
    createAction,
  } = useTimeBasedAction({
    active: !!authHeader.current,
    timeBasedAction,
    keyCodes: ['ArrowUp', 'ArrowDown', 'ArrowRight', 'ArrowLeft'],
  })

  const unquoteCredential = v => JSON.parse(`"${v}"`)

  const linkToIceServers = links =>
    links !== null
      ? links.split(', ').map(link => {
          const m = link.match(
            /^<(.+?)>; rel="ice-server"(; username="(.*?)"; credential="(.*?)"; credential-type="password")?/i
          )
          const ret = {
            urls: [m[1]],
          }

          if (m[3] !== undefined) {
            ret.username = unquoteCredential(m[3])
            ret.credential = unquoteCredential(m[4])
            ret.credentialType = 'password'
          }

          return ret
        })
      : []

  const parseOffer = sdp => {
    const ret = {
      iceUfrag: '',
      icePwd: '',
      medias: [],
    }

    for (const line of sdp.split('\r\n')) {
      if (line.startsWith('m=')) {
        ret.medias.push(line.slice('m='.length))
      } else if (ret.iceUfrag === '' && line.startsWith('a=ice-ufrag:')) {
        ret.iceUfrag = line.slice('a=ice-ufrag:'.length)
      } else if (ret.icePwd === '' && line.startsWith('a=ice-pwd:')) {
        ret.icePwd = line.slice('a=ice-pwd:'.length)
      }
    }

    return ret
  }

  const enableStereoPcmau = section => {
    let lines = section.split('\r\n')

    lines[0] += ' 118'
    lines.splice(lines.length - 1, 0, 'a=rtpmap:118 PCMU/8000/2')
    lines.splice(lines.length - 1, 0, 'a=rtcp-fb:118 transport-cc')

    lines[0] += ' 119'
    lines.splice(lines.length - 1, 0, 'a=rtpmap:119 PCMA/8000/2')
    lines.splice(lines.length - 1, 0, 'a=rtcp-fb:119 transport-cc')

    return lines.join('\r\n')
  }

  const enableMultichannelOpus = section => {
    let lines = section.split('\r\n')

    lines[0] += ' 112'
    lines.splice(lines.length - 1, 0, 'a=rtpmap:112 multiopus/48000/3')
    lines.splice(lines.length - 1, 0, 'a=fmtp:112 channel_mapping=0,2,1;num_streams=2;coupled_streams=1')
    lines.splice(lines.length - 1, 0, 'a=rtcp-fb:112 transport-cc')

    lines[0] += ' 113'
    lines.splice(lines.length - 1, 0, 'a=rtpmap:113 multiopus/48000/4')
    lines.splice(lines.length - 1, 0, 'a=fmtp:113 channel_mapping=0,1,2,3;num_streams=2;coupled_streams=2')
    lines.splice(lines.length - 1, 0, 'a=rtcp-fb:113 transport-cc')

    lines[0] += ' 114'
    lines.splice(lines.length - 1, 0, 'a=rtpmap:114 multiopus/48000/5')
    lines.splice(lines.length - 1, 0, 'a=fmtp:114 channel_mapping=0,4,1,2,3;num_streams=3;coupled_streams=2')
    lines.splice(lines.length - 1, 0, 'a=rtcp-fb:114 transport-cc')

    lines[0] += ' 115'
    lines.splice(lines.length - 1, 0, 'a=rtpmap:115 multiopus/48000/6')
    lines.splice(lines.length - 1, 0, 'a=fmtp:115 channel_mapping=0,4,1,2,3,5;num_streams=4;coupled_streams=2')
    lines.splice(lines.length - 1, 0, 'a=rtcp-fb:115 transport-cc')

    lines[0] += ' 116'
    lines.splice(lines.length - 1, 0, 'a=rtpmap:116 multiopus/48000/7')
    lines.splice(lines.length - 1, 0, 'a=fmtp:116 channel_mapping=0,4,1,2,3,5,6;num_streams=4;coupled_streams=4')
    lines.splice(lines.length - 1, 0, 'a=rtcp-fb:116 transport-cc')

    lines[0] += ' 117'
    lines.splice(lines.length - 1, 0, 'a=rtpmap:117 multiopus/48000/8')
    lines.splice(lines.length - 1, 0, 'a=fmtp:117 channel_mapping=0,6,1,4,5,2,3,7;num_streams=5;coupled_streams=4')
    lines.splice(lines.length - 1, 0, 'a=rtcp-fb:117 transport-cc')

    return lines.join('\r\n')
  }

  const enableL16 = section => {
    let lines = section.split('\r\n')

    lines[0] += ' 120'
    lines.splice(lines.length - 1, 0, 'a=rtpmap:120 L16/8000/2')
    lines.splice(lines.length - 1, 0, 'a=rtcp-fb:120 transport-cc')

    lines[0] += ' 121'
    lines.splice(lines.length - 1, 0, 'a=rtpmap:121 L16/16000/2')
    lines.splice(lines.length - 1, 0, 'a=rtcp-fb:121 transport-cc')

    lines[0] += ' 122'
    lines.splice(lines.length - 1, 0, 'a=rtpmap:122 L16/48000/2')
    lines.splice(lines.length - 1, 0, 'a=rtcp-fb:122 transport-cc')

    return lines.join('\r\n')
  }

  const enableStereoOpus = section => {
    let opusPayloadFormat = ''
    let lines = section.split('\r\n')

    for (let i = 0; i < lines.length; i++) {
      if (lines[i].startsWith('a=rtpmap:') && lines[i].toLowerCase().includes('opus/')) {
        opusPayloadFormat = lines[i].slice('a=rtpmap:'.length).split(' ')[0]
        break
      }
    }

    if (opusPayloadFormat === '') {
      return section
    }

    for (let i = 0; i < lines.length; i++) {
      if (lines[i].startsWith('a=fmtp:' + opusPayloadFormat + ' ')) {
        if (!lines[i].includes('stereo')) {
          lines[i] += ';stereo=1'
        }
        if (!lines[i].includes('sprop-stereo')) {
          lines[i] += ';sprop-stereo=1'
        }
      }
    }

    return lines.join('\r\n')
  }

  const editOffer = sdp => {
    const sections = sdp.split('m=')

    for (let i = 0; i < sections.length; i++) {
      if (sections[i].startsWith('audio')) {
        sections[i] = enableStereoOpus(sections[i])

        if (nonAdvertisedCodecs.includes('pcma/8000/2')) {
          sections[i] = enableStereoPcmau(sections[i])
        }

        if (nonAdvertisedCodecs.includes('multiopus/48000/6')) {
          sections[i] = enableMultichannelOpus(sections[i])
        }

        if (nonAdvertisedCodecs.includes('L16/48000/2')) {
          sections[i] = enableL16(sections[i])
        }

        break
      }
    }

    return sections.join('m=')
  }

  const generateSdpFragment = (od, candidates) => {
    const candidatesByMedia = {}
    for (const candidate of candidates) {
      const mid = candidate.sdpMLineIndex
      if (candidatesByMedia[mid] === undefined) {
        candidatesByMedia[mid] = []
      }
      candidatesByMedia[mid].push(candidate)
    }

    let frag = 'a=ice-ufrag:' + od.iceUfrag + '\r\n' + 'a=ice-pwd:' + od.icePwd + '\r\n'

    let mid = 0

    for (const media of od.medias) {
      if (candidatesByMedia[mid] !== undefined) {
        frag += 'm=' + media + '\r\n' + 'a=mid:' + mid + '\r\n'

        for (const candidate of candidatesByMedia[mid]) {
          frag += 'a=' + candidate.candidate + '\r\n'
        }
      }
      mid++
    }

    return frag
  }

  const loadStream = () => {
    requestICEServers()
  }

  const supportsNonAdvertisedCodec = (codec, fmtp) =>
    new Promise(resolve => {
      const pc = new RTCPeerConnection({ iceServers: [] })
      pc.addTransceiver('audio', { direction: 'recvonly' })
      pc.createOffer()
        .then(offer => {
          if (offer.sdp.includes(' ' + codec)) {
            // codec is advertised, there's no need to add it manually
            resolve(false)
            return
          }
          const sections = offer.sdp.split('m=audio')
          const lines = sections[1].split('\r\n')
          lines[0] += ' 118'
          lines.splice(lines.length - 1, 0, 'a=rtpmap:118 ' + codec)
          if (fmtp !== undefined) {
            lines.splice(lines.length - 1, 0, 'a=fmtp:118 ' + fmtp)
          }
          sections[1] = lines.join('\r\n')
          offer.sdp = sections.join('m=audio')
          return pc.setLocalDescription(offer)
        })
        .then(() => {
          return pc.setRemoteDescription(
            new RTCSessionDescription({
              type: 'answer',
              sdp:
                'v=0\r\n' +
                'o=- 6539324223450680508 0 IN IP4 0.0.0.0\r\n' +
                's=-\r\n' +
                't=0 0\r\n' +
                'a=fingerprint:sha-256 0D:9F:78:15:42:B5:4B:E6:E2:94:3E:5B:37:78:E1:4B:54:59:A3:36:3A:E5:05:EB:27:EE:8F:D2:2D:41:29:25\r\n' +
                'm=audio 9 UDP/TLS/RTP/SAVPF 118\r\n' +
                'c=IN IP4 0.0.0.0\r\n' +
                'a=ice-pwd:7c3bf4770007e7432ee4ea4d697db675\r\n' +
                'a=ice-ufrag:29e036dc\r\n' +
                'a=sendonly\r\n' +
                'a=rtcp-mux\r\n' +
                'a=rtpmap:118 ' +
                codec +
                '\r\n' +
                (fmtp !== undefined ? 'a=fmtp:118 ' + fmtp + '\r\n' : ''),
            })
          )
        })
        .then(() => {
          resolve(true)
        })
        .catch(() => {
          resolve(false)
        })
        .finally(() => {
          pc.close()
        })
    })

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const getNonAdvertisedCodecs = () => {
    Promise.all(
      [
        ['pcma/8000/2'],
        ['multiopus/48000/6', 'channel_mapping=0,4,1,2,3,5;num_streams=4;coupled_streams=2'],
        ['L16/48000/2'],
      ].map(c => supportsNonAdvertisedCodec(c[0], c[1]).then(r => (r ? c[0] : false)))
    )
      .then(c => c.filter(e => e !== false))
      .then(codecs => {
        setNonAdvertisedCodecs(codecs)
        loadStream()
      })
  }

  const onError = () => {
    if (restartTimeout === null) {
      if (pc.current !== null) {
        pc.current.close()
        pc.current = null
      }

      setTimeout(() => {
        loadStream()
      }, 2000)

      if (sessionUrl) {
        fetch(sessionUrl, {
          method: 'DELETE',
        })
      }
      setSessionUrl('')

      setQueuedCandidates([])
    }
  }

  const sendLocalCandidates = candidates => {
    fetch('https://webrtc.168robotics.com/test-video', {
      method: 'PATCH',
      headers: {
        Authorization: authHeader.current,
        'Content-Type': 'application/trickle-ice-sdpfrag',
        'If-Match': '*',
      },
      body: generateSdpFragment(offerData, candidates),
    })
      .then(res => {
        switch (res.status) {
          case 204:
            break
          case 404:
            throw new Error('stream not found')
          default:
            throw new Error(`bad status code ${res.status}`)
        }
      })
      .catch(err => {
        onError(err.toString())
      })
  }

  const onLocalCandidate = evt => {
    if (restartTimeout !== null) {
      return
    }

    if (evt.candidate !== null) {
      if (sessionUrl === '') {
        setQueuedCandidates([...queuedCandidates, evt.candidate])
      } else {
        sendLocalCandidates([evt.candidate])
      }
    }
  }

  const onRemoteAnswer = sdp => {
    if (restartTimeout !== null) {
      return
    }

    pc.current
      .setRemoteDescription(
        new RTCSessionDescription({
          type: 'answer',
          sdp,
        })
      )
      .then(() => {
        if (queuedCandidates.length !== 0) {
          sendLocalCandidates(queuedCandidates)
          setQueuedCandidates([])
        }
      })
      .catch(err => {
        onError(err.toString())
      })
  }

  const sendOffer = offer => {
    fetch(webrtcUrl, {
      method: 'POST',
      headers: {
        Authorization: authHeader.current,
        'Content-Type': 'application/sdp',
      },
      body: offer.sdp,
    })
      .then(res => {
        switch (res.status) {
          case 201:
            break
          case 404:
            throw new Error('stream not found')
          case 400:
            return res.json().then(e => {
              throw new Error(e.error)
            })
          default:
            throw new Error(`bad status code ${res.status}`)
        }

        // sessionUrl = new URL(res.headers.get('location'), window.location.href).toString()
        setSessionUrl(`https://webrtc.168robotics.com${res.headers.get('location')}`)

        return res.text().then(sdp => onRemoteAnswer(sdp))
      })
      .catch(err => {
        onError(err.toString())
      })
  }

  const createOffer = () => {
    pc.current
      .createOffer()
      .then(offer => {
        offer.sdp = editOffer(offer.sdp)
        setOfferData(parseOffer(offer.sdp))
        pc.current
          .setLocalDescription(offer)
          .then(() => {
            sendOffer(offer)
          })
          .catch(err => {
            onError(err.toString())
          })
      })
      .catch(err => {
        onError(err.toString())
      })
  }

  const onConnectionState = () => {
    if (restartTimeout !== null) {
      return
    }

    if (pc.iceConnectionState === 'disconnected') {
      onError('peer connection closed')
    }
  }

  const onTrack = evt => {
    video.current.srcObject = evt.streams[0]
  }

  const requestICEServers = () => {
    fetch(webrtcUrl, {
      method: 'OPTIONS',
      // headers: headers.current,
      headers: {
        Authorization: authHeader.current,
      },
    })
      .then(res => {
        if (res.status === 401) {
          authHeader.current = null
          return setShowModal(true)
        }
        pc.current = new RTCPeerConnection({
          iceServers: linkToIceServers(res.headers.get('Link')),
          // https://webrtc.org/getting-started/unified-plan-transition-guide
          sdpSemantics: 'unified-plan',
        })

        const direction = 'sendrecv'
        pc.current.addTransceiver('video', { direction })
        pc.current.addTransceiver('audio', { direction })

        pc.current.onicecandidate = evt => onLocalCandidate(evt)
        pc.current.oniceconnectionstatechange = () => onConnectionState()
        pc.current.ontrack = evt => onTrack(evt)

        createOffer()
      })
      .catch(err => {
        onError(err.toString())
      })
  }

  const handleSubmit = e => {
    e.preventDefault()
    setShowModal(false)

    const login = e.target.login.value
    const password = e.target.password.value
    authHeader.current = 'Basic ' + window.btoa(`${login}:${password}`)

    initSocket()
    teleopInit()
  }

  useEffect(() => {
    // const password = prompt('Enter password')
    // if (!password) {
    //   return
    // }
    // authHeader.current = 'Basic ' + window.btoa(`test-user:${password}`)
    if (!authHeader.current) return
    getNonAdvertisedCodecs()
  }, [authHeader.current])

  return (
    <div className={style.container}>
      <Modal open={showModal} onClose={() => setShowModal(false)}>
        <Box width="100%" height="100%" display="flex" justifyContent="center" alignItems="center">
          <Box
            minHeight="20%"
            minWidth="30%"
            bgcolor="info.main"
            borderRadius={3}
            p={2}
            sx={{ textAlign: 'center', transform: { xs: 'rotate(90deg);', sm: 'rotate(0deg);' } }}
          >
            <form onSubmit={handleSubmit}>
              <Box display="flex" flexDirection="column" alignItems="stretch" gap={1} height="100%">
                <Typography variant="h5">Sign in</Typography>
                <TextField label="Логин" variant="outlined" name="login" id="login" />
                <TextField label="Пароль" variant="outlined" type="password" name="password" id="password" />
                <Button variant="contained" type="submit">
                  Sign in
                </Button>
              </Box>
            </form>
          </Box>
        </Box>
      </Modal>
      <Grid className={style.manualMode} padding={2} container height="100%" width="100%">
        <Grid xs={12} md={7} item paddingY={2} height="100%" paddingRight={2}>
          <Box bgcolor="info.main" height="100%" borderRadius={4} overflow="auto">
            <Stack
              paddingLeft={2}
              paddingRight={2}
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              height="5%"
            >
              <Box paddingTop={1}>
                <Typography variant="textDefault">Manual mode</Typography>
                <IconButton size="small" color="inherit" onClick={() => handleBackClicked()}>
                  <IoMdCloseCircleOutline />
                </IconButton>
              </Box>
              <Tooltip title="???" placement="top">
                <IconButton size="small">
                  <QuestionMarkIcon />
                </IconButton>
              </Tooltip>
            </Stack>
            <Grid
              item
              paddingLeft={2}
              paddingRight={2}
              height="95%"
              display={'flex'}
              flexDirection="column"
              justifyContent="space-between"
            >
              <Grid
                item
                position="relative"
                display="flex"
                justifyContent="center"
                paddingTop={1}
                paddingBottom={2}
                maxHeight="70%"
                minHeight="60%"
              >
                <Box width="100%" height="100%">
                  <video className={style.video} ref={video} id="test_video" controls autoPlay></video>
                </Box>
                <Stack spacing={{ xs: 0.5, lg: 2 }} sx={{ position: 'absolute', top: '5%', right: '3%' }}>
                  <Box className={style.buttonManual}>
                    <IconButton>
                      <CameraSvg />
                    </IconButton>
                  </Box>
                  <Box className={style.buttonManual}>
                    <IconButton>
                      <DegreeSvg />
                    </IconButton>
                  </Box>
                  <Box className={style.buttonManual}>
                    <IconButton>
                      <IconCamSvg />
                    </IconButton>
                  </Box>
                </Stack>
              </Grid>
              <Grid display="flex" alignItems="center" height="100%">
                <Stack
                  direction={{ xs: 'column', sm: 'row' }}
                  width="100%"
                  justifyContent="space-between"
                  paddingBottom={2}
                >
                  <Stack spacing={2}>
                    <Box height="100%">
                      <ManualDataBlock header="Wheels turning angle, °" value={rudder || '-'} tooltip="" />
                    </Box>
                    <Box height="100%">
                      <ManualDataBlock
                        header="Speed, km/h"
                        value={engine || '-'}
                        tooltip=""
                        subtitle="Set limit"
                        // onClickFunction={() => setOpenModal(true)}
                      />
                    </Box>
                  </Stack>
                  <Box margin="35px">
                    <RobotJoystick
                      baseColor="rgba(255, 255, 255, 0.8)"
                      keyboard={!!authHeader.current}
                      onEngine={val => {
                        teleopMovement('engine', val)
                      }}
                      onRudder={val => teleopMovement('rudder', val)}
                    />
                  </Box>

                  {/* <Grid item minWidth="40%" justifyContent="center" display="flex" alignItems="center">
                    <Box
                      // width={height < 800 ? '11vw' : { xs: '25vw', sm: '25vw', md: '14vw' }}
                      // height={height < 800 ? '11vw' : { xs: '25vw', sm: '25vw', md: '14vw' }}
                      width={{ xs: '130px', md: '150px', lg: '200px' }}
                      height={{ xs: '130px', md: '150px', lg: '200px' }}
                      borderRadius="50%"
                      bgcolor="#FFFFFF"
                      border={5}
                      borderColor="#E4E5E8"
                      position="relative"
                    >
                      <Stack direction="row" height="30%">
                        <IconButton
                          sx={{
                            fontSize: 40,
                            width: '100%',
                            '&:hover': {
                              backgroundColor: 'transparent',
                            },
                          }}
                        >
                          <BsCaretUpFill />
                        </IconButton>
                      </Stack>
                      <Stack direction="row" justifyContent="Space-between" height="40%">
                        <IconButton
                          sx={{
                            fontSize: 40,
                            padding: 0,
                            '&:hover': {
                              backgroundColor: 'transparent',
                            },
                          }}
                        >
                          <BsCaretLeftFill />
                        </IconButton>
                        <IconButton
                          sx={{
                            width: '40%',
                            backgroundColor: '#F4F5F8',
                            padding: 0,
                            fontSize: 40,
                            '&:hover': {
                              backgroundColor: '#F4F5F8',
                            },
                          }}
                        >
                          <BsPauseFill />
                        </IconButton>
                        <IconButton
                          sx={{
                            fontSize: 40,
                            padding: 0,
                            '&:hover': {
                              backgroundColor: 'transparent',
                            },
                          }}
                        >
                          <BsCaretRightFill />
                        </IconButton>
                      </Stack>
                      <Stack direction="row" height="30%">
                        <IconButton
                          sx={{
                            width: '100%',
                            fontSize: 40,
                            '&:hover': {
                              backgroundColor: 'transparent',
                            },
                          }}
                        >
                          <BsCaretDownFill />
                        </IconButton>
                      </Stack>
                    </Box>
                  </Grid> */}

                  <Stack spacing={2}>
                    <Box height="100%">
                      <ManualDataBlock
                        header="Sound notification"
                        value={heartbit || '-'}
                        tooltip=""
                        subtitle="Latency: 10.10.23 10:10 am"
                        showButtonIcon
                        onSignal={teleopSoundOn}
                        offSignal={teleopSoundOff}
                      />
                    </Box>
                    <Box height="100%">
                      <ManualDataBlock header="Passed time" value={'0:21'} tooltip="" />
                    </Box>
                  </Stack>
                </Stack>
              </Grid>
            </Grid>
          </Box>
        </Grid>
        {width > 500 && (
          <Grid item xs={12} md={5} height="100%" paddingY={2}>
            <Box width="100%" height="100%" borderRadius={2} overflow="hidden">
              <RobotMissionMap />
            </Box>
          </Grid>
        )}
      </Grid>

      <ManualModeMobile
        className={style.manualModeMobileWrapper}
        engine={engine}
        rudder={rudder}
        heartbit={heartbit}
        onBackPage={handleBackClicked}
        onSignal={teleopSoundOn}
        offSignal={teleopSoundOff}
        onEngine={val => {
          teleopMovement('engine', val)
        }}
        onRudder={val => {
          teleopMovement('rudder', val)
        }}
        onStop={createAction}
        onStart={() => {
          if (idAction.current) clearAction()
          else teleopReload()
        }}
      />

      {/* <ManualDataModal openModal={openModal} onClickFunction={setOpenModal} /> */}
    </div>
  )
}

export default ManualModePage
