import { useEffect, useRef } from 'react'

export interface TimeBasedAction {
  time: number
  onInactivity: () => void
  onActivity: () => void
}

interface Props {
  active?: boolean
  timeBasedAction: TimeBasedAction
  keyCodes?: string[]
}

function useTimeBasedAction({ active = true, timeBasedAction, keyCodes }: Props) {
  const id = useRef<NodeJS.Timeout | null>(null)
  const isInactivity = useRef(false)

  useEffect(() => {
    if (!timeBasedAction || !active) return

    const keydownHandler = () => {
      if (isInactivity.current) {
        timeBasedAction.onActivity()
        isInactivity.current = false
      }
    }

    const createTimeoutAction = (e: KeyboardEvent) => {
      if (Array.isArray(keyCodes) && !keyCodes.includes(e.code)) return

      if (id.current !== null) clearAction()

      createAction()
    }

    if (Array.isArray(keyCodes)) {
      document.addEventListener('keydown', keydownHandler)
      document.addEventListener('keyup', createTimeoutAction)
    }

    return () => {
      if (Array.isArray(keyCodes)) {
        document.removeEventListener('keydown', keydownHandler)
        document.removeEventListener('keyup', createTimeoutAction)
      }
    }
  }, [timeBasedAction, keyCodes, active])

  useEffect(() => {
    if (!timeBasedAction || !active) return
    createAction()

    return () => {
      clearAction()
    }
  }, [timeBasedAction, active])

  const clearAction = () => {
    if (id.current === null) return
    clearTimeout(id.current)
    id.current = null
  }

  const createAction = () => {
    id.current = setTimeout(() => {
      timeBasedAction.onInactivity()
      isInactivity.current = true
      id.current = null
    }, timeBasedAction.time)
  }

  return { clearAction, createAction, id }
}

export default useTimeBasedAction
