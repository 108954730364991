import { MainApi } from 'api/MainApi'
import { ApiError, GeoTiffLayerSchema, MissionGeoTiffLayers } from 'api/schema'
import { GeoTiffLayer } from 'api/schema/models/GeoTiffLayer'
import { Map } from 'ol'
import WebGLTileLayer from 'ol/layer/WebGLTile'
import { transformExtent } from 'ol/proj'
import { create } from 'zustand'

interface MapStore {
  map?: Map
  zoom: number
  extent: number[]
  isSatellite: boolean
  missionExtent: number[] | null
  geoTiffsInExtent: GeoTiffLayer[]
  displayGeotiffs: GeoTiffLayerSchema[]
  setMap: (map: Map) => void
  setSatelliteBaseMap: (val: boolean) => void
  setMissionExtent: (extent: number[] | null) => void
  getGeoTiffByUrl: (url?: string) => WebGLTileLayer | undefined
  setDisplayedGeotiffs: (geotiffs: GeoTiffLayerSchema[]) => void
  getGeotiffsInExtent: () => Promise<void>
  saveGeotiffForMission: (missionId: number) => Promise<MissionGeoTiffLayers | Error>
  toggleGeotiff: (id: number) => void
  uploadGeotiff: (file: File, missionId: number) => Promise<GeoTiffLayer | ApiError>
  checkGeotiffStatus: (id: number) => Promise<GeoTiffLayer | Error>
}

const useMapStore = create<MapStore>((set, get) => ({
  isSatellite: false,
  geoTiffsInExtent: [],
  displayGeotiffs: [],
  missionExtent: null,
  extent: [],
  zoom: 0,
  setMap: map => {
    map.on('moveend', () => {
      const zoom = map.getView().getZoom()
      const extent = map.getView().calculateExtent()
      const lonLatExtent = transformExtent(extent, 'EPSG:3857', 'EPSG:4326')
      set(() => ({ zoom, extent: lonLatExtent }))
    })
    set(() => ({ map }))
  },
  setSatelliteBaseMap: val => set(() => ({ isSatellite: val })),
  setMissionExtent: extent => set(() => ({ missionExtent: extent })),
  getGeoTiffByUrl: url => {
    if (!url) return

    return get()
      .map?.getLayers()
      .getArray()
      .find(l => l instanceof WebGLTileLayer && l.getSource()?.getKey() === url) as WebGLTileLayer
  },
  setDisplayedGeotiffs: geoTiffs => set(() => ({ displayGeotiffs: geoTiffs })),
  getGeotiffsInExtent: async () => {
    const extent = get().map?.getView().calculateExtent()
    if (!extent) return
    const lonLatExtent = transformExtent(extent, 'EPSG:3857', 'EPSG:4326')
    const geoTiffs = await MainApi.fetchGeoTiffsInExtent(lonLatExtent)
    return set(() => ({ geoTiffsInExtent: geoTiffs }))
  },
  toggleGeotiff: id => {
    const geotiffs = [...get().displayGeotiffs]
    const index = geotiffs.findIndex(g => g.id === id)
    if (index > -1) {
      geotiffs.splice(index, 1)
    } else {
      const geoTiff = get().geoTiffsInExtent.find(g => g.id === id)
      if (geoTiff) geotiffs.push(geoTiff)
    }
    set(() => ({ displayGeotiffs: geotiffs }))
  },
  saveGeotiffForMission: async (missionId: number) => {
    const geoTiffs = get().displayGeotiffs.map(g => {
      const opacity = get()
        .getGeoTiffByUrl(g.file?.replace('https://testfms.168robotics.com', ''))
        ?.getOpacity()

      return {
        id: g.id,
        opacity: opacity || 1,
        sort_order: 1,
      }
    })
    try {
      const response = await MainApi.saveGeoTiffsForMission(missionId, { layers: geoTiffs })
      return response
    } catch (error) {
      return error as Error
    }
  },
  uploadGeotiff: async (file, missionId) => {
    try {
      const response = await MainApi.uploadNewGeotiff(missionId, file)
      return response
    } catch (error) {
      return error as ApiError
    }
  },
  checkGeotiffStatus: async (id: number) => {
    try {
      const response = await MainApi.checkGeotiffStatus(id)
      return response
    } catch (error) {
      return error as Error
    }
  },
}))

export default useMapStore
