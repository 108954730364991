import { Feature } from 'ol'
import { asArray } from 'ol/color'
import LineString from 'ol/geom/LineString'
import VectorLayer from 'ol/layer/Vector'
import VectorSource from 'ol/source/Vector'
import { useEffect, useState } from 'react'
import useMapStore from 'store/map'
import { ITrajectory, IVisualizationSettings } from 'utils/interfaces'

interface Props {
  transferRoutes: ITrajectory[]
  visualSettings: IVisualizationSettings
  zoomToRoutes?: boolean
}

function TransferRoutesLayer({ transferRoutes, visualSettings, zoomToRoutes }: Props) {
  const map = useMapStore(s => s.map)
  const setMissionExtent = useMapStore(s => s.setMissionExtent)
  const [layer, setLayer] = useState<VectorLayer<VectorSource<Feature<LineString>>> | null>(null)

  useEffect(() => {
    layer?.getSource()?.clear()
    layer?.getSource()?.addFeatures(
      transferRoutes.map(route => {
        const feature = new Feature({
          geometry: new LineString(route.geometry.coordinates).transform('EPSG:4326', 'EPSG:3857'),
        })
        feature.setId(route.id)
        return feature
      })
    )
  }, [transferRoutes, layer])

  useEffect(() => {
    const strokeColor = asArray(visualSettings.transfer_route_color)
    strokeColor[3] = visualSettings.transfer_route_opacity

    const layer = new VectorLayer({
      source: new VectorSource({
        features: transferRoutes.map(route => {
          const feature = new Feature({
            geometry: new LineString(route.geometry.coordinates).transform('EPSG:4326', 'EPSG:3857'),
          })
          feature.setId(route.id)
          return feature
        }),
      }),
      properties: {
        name: 'Transfer Routes',
        showInLayerList: true,
      },
      style: {
        'stroke-color': strokeColor,
        'stroke-width': visualSettings.transfer_route_width,
      },
      zIndex: 4,
      opacity: visualSettings.transfer_route_opacity,
    })

    map?.addLayer(layer)
    setLayer(layer)

    if (zoomToRoutes) {
      const extent = layer.getSource()?.getExtent()
      if (extent && !extent?.some(e => e === Infinity || e === -Infinity)) {
        map?.getView().fit(extent, { padding: [50, 50, 50, 50] })
        setMissionExtent(extent)
      }
    }

    return () => {
      map?.removeLayer(layer)
      setMissionExtent(null)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [map])

  useEffect(() => {
    const strokeColor = asArray(visualSettings.transfer_route_color)
    strokeColor[3] = visualSettings.transfer_route_opacity

    layer?.setStyle({
      'stroke-color': strokeColor,
      'stroke-width': visualSettings.transfer_route_width,
    })
    layer?.setOpacity(visualSettings.transfer_route_opacity)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visualSettings])

  return null
}

export default TransferRoutesLayer
