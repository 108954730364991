import { Point } from 'ol/geom'
import useMapStore from 'store/map'

const useZoomToPoint = () => {
  const map = useMapStore(s => s.map)

  const zoomToPoint = (point: Point) => {
    const pointExtent = point.getExtent()
    map?.getView().fit(pointExtent, { padding: [100, 100, 100, 100], maxZoom: 20 })
  }

  return { zoomToPoint }
}

export default useZoomToPoint
