import { Button, IconButton, Stack, Tooltip, Typography } from '@mui/material'
import { ReactComponent as ChargeIcon } from 'images/icons/charge.svg'
import { ReactComponent as QuestionMarkIcon } from 'images/icons/faq.svg'
import { HiMiniSignal } from 'react-icons/hi2'

interface Props {
  header: string
  value?: number | string
  tooltip: string
  subtitle?: string
  showChargeIcon?: boolean
  showButtonIcon?: boolean
  onClickFunction?: () => void
  onSignal: () => void
  offSignal: () => void
}

const ManualDataBlock = ({
  header,
  value,
  tooltip,
  subtitle,
  showChargeIcon,
  showButtonIcon,
  onClickFunction,
  onSignal,
  offSignal,
}: Props) => {
  return (
    <Stack direction="column" height="100%" borderRadius={2} paddingX={1} bgcolor={'white'}>
      <Stack paddingTop={1} direction="row" alignItems="center" justifyContent="space-between">
        <Typography variant="headerTile" color="#9E9E9E">
          {header}
        </Typography>
        <Tooltip title={tooltip} placement="top">
          <IconButton size="small">
            <QuestionMarkIcon />
          </IconButton>
        </Tooltip>
      </Stack>
      <Stack height="80%" display="flex" justifyContent="center">
        {showButtonIcon ? (
          <Button
            variant="contained"
            color="primary"
            fullWidth
            startIcon={<HiMiniSignal />}
            onMouseDown={onSignal}
            onMouseUp={offSignal}
          >
            Give a signal
          </Button>
        ) : (
          <Typography variant="textTile" align="center">
            {value} {showChargeIcon && <ChargeIcon />}
          </Typography>
        )}

        {onClickFunction !== undefined ? (
          <Button
            variant="text"
            onClick={() => onClickFunction()}
            sx={{ cursor: 'pointer', textDecoration: 'underline', color: '#424040', fontWeight: '400' }}
          >
            {subtitle || ''}
          </Button>
        ) : (
          <Typography variant="subTitleTile" padding={1} align="center" color="#424040">
            {subtitle || ''}
          </Typography>
        )}
      </Stack>
    </Stack>
  )
}

export default ManualDataBlock
